import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAppSettingsState {
  isSidebarShort: boolean;
}

const initialState: IAppSettingsState = {
  isSidebarShort: localStorage.getItem('is_sidebar_short')
    ? Boolean(localStorage.getItem('is_sidebar_short'))
    : false,
};

const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    setSidebarShort: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isSidebarShort: action.payload,
    }),
  },
});

export const appSettingsReducer = appSettingsSlice.reducer;
export const appSettingsActions = appSettingsSlice.actions;

import { combineReducers } from '@reduxjs/toolkit';

import { airExchangeReducer } from '@/core/redux/slices/airExchange/slice';
import { authReducer } from '@/core/redux/slices/auth/slice';
import { buildingObjectModalsReducer } from '@/core/redux/slices/modals/smokeExtraction/buildingObject/slice';
import { calculationModalsReducer } from '@/core/redux/slices/modals/smokeExtraction/calculation/slice';
import { smokeHatchesModalsReducer } from '@/core/redux/slices/modals/smokeExtraction/smokeHatches/slice';
import { systemnModalsReducer } from '@/core/redux/slices/modals/smokeExtraction/system/slice';
import { productsReducer } from '@/core/redux/slices/products/slice';
import { buildingObjectReducer } from '@/core/redux/slices/smokeExtraction/buildingObject/slice';
import { calctulationReducer } from '@/core/redux/slices/smokeExtraction/calculation/slice';
import { systemCalculationsReducer } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemParamsReducer } from '@/core/redux/slices/smokeExtraction/system/params/slice';
import { reportReducer } from '@/core/redux/slices/smokeExtraction/system/report/slice';
import { systemReducer } from '@/core/redux/slices/smokeExtraction/system/slice';
import { tourReducer } from '@/core/redux/slices/tour/slice';
import { usersReducer } from '@/core/redux/slices/users/slice';

import { appSettingsReducer } from '../slices/app/slice';

export const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  buildingObject: buildingObjectReducer,
  calculation: calctulationReducer,
  system: combineReducers({
    params: systemParamsReducer,
    root: systemReducer,
    report: reportReducer,
    calculations: systemCalculationsReducer,
  }),
  modals: combineReducers({
    buildingObjectModals: buildingObjectModalsReducer,
    calculationModals: calculationModalsReducer,
    systemModals: systemnModalsReducer,
    smokeHatchesModals: smokeHatchesModalsReducer,
  }),
  tour: tourReducer,
  airExchange: airExchangeReducer,
  products: productsReducer,
  appSettings: appSettingsReducer,
});

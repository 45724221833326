import { useEffect, useRef, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

export interface ISystemInformation {
  system: any;
  onClosePage: any;
  continueCalculation: any;
}

export const SystemInformation: React.FC<ISystemInformation> = ({
  system,
  onClosePage,
  continueCalculation,
}) => {
  const inactiviti_count = Number(process.env.REACT_APP_INACTIVITY_COUNT);
  const unlock_count = Number(process.env.REACT_APP_UNLOCK_COUNT);

  const [inactivitiTimer, setInactivitiTimer] =
    useState<number>(inactiviti_count);
  const [unlockTimer, setUnlockTimer] = useState<number>(unlock_count);
  const [checkContinueAction, setAction] = useState<boolean>(false);

  const inactivitiTimerRef = useRef<any>(null);
  const unlockTimerRef = useRef<any>(null);

  const { confirm } = Modal;

  useEffect(() => {
    if (inactivitiTimer === 0) {
      clearInterval(inactivitiTimerRef.current);
      showInactivitiModal();
      const unlockCountDown = () => {
        setUnlockTimer((prev: any) => prev - 1);
      };
      const internalUnlockTimerId = setInterval(unlockCountDown, 1000);
      unlockTimerRef.current = internalUnlockTimerId;
    }
    return () => clearInterval(unlockTimerRef.current);
  }, [inactivitiTimer]);

  useEffect(() => {
    if (unlockTimer === 0) {
      clearInterval(unlockTimerRef.current);
      Modal.destroyAll();
      showUnlockModal();
    }
  }, [unlockTimerRef.current, unlockTimer]);

  useEffect(() => {
    setInactivitiTimer(inactiviti_count);
  }, [system]);

  useEffect(() => {
    const inactivitiCoutDown = () => {
      setInactivitiTimer((prev: any) => prev - 1);
    };
    const internalTimerId = setInterval(inactivitiCoutDown, 1000);
    inactivitiTimerRef.current = internalTimerId;
    return () => clearInterval(internalTimerId);
  }, [checkContinueAction]);

  const continueAction = () => {
    clearInterval(unlockTimerRef.current);
    continueCalculation();
    setInactivitiTimer(inactiviti_count);
    setUnlockTimer(unlock_count);
    setAction(!checkContinueAction);
  };

  const showUnlockModal = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Обновите страницу, чтобы продолжить',
      content: (
        <div>
          <div>Вы не редактировали расчет некоторое время,</div>
          <div>поэтому мы сохранили расчет</div>
        </div>
      ),
      okText: 'Обновить страницу',
      onOk: () => location.reload(),
      onCancel: () => onClosePage(),
      cancelText: 'Закрыть расчет',
      centered: true,
    });
  };

  const showInactivitiModal = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Вы еще редактируете расчет?',
      content: (
        <div>
          <div>Вы не вносили изменения некоторое время.</div>
          <div>Дайте нам знать, если вы еще на месте.</div>
        </div>
      ),
      okText: 'Вернуться в расчет',
      onOk: () => continueAction(),
      onCancel: () => onClosePage(),
      cancelText: 'Закрыть расчет',
      centered: true,
    });
  };

  return <></>;
};

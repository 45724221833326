import { FocusEvent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Typography } from 'antd';
import cloneDeep from 'lodash/cloneDeep';

import { InputNumberForm } from '@/components/form/InputNumber';
import { SelectForm } from '@/components/form/Select';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemCalculationsActions } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';
import LocationDeviceImage from '@/images/Location_of_smoke_extraction_devices.png';

const { Title } = Typography;

export interface ICalculationOfSmokeRemovalDevicesView {
  systemID: number;
  isObserver: boolean;
}

export const CalculationOfSmokeRemovalDevicesView: React.FC<
  ICalculationOfSmokeRemovalDevicesView
> = ({ systemID, isObserver }) => {
  const systemState = useAppSelector(systemSelectors.systemState);

  const dispatch = useAppDispatch();

  const methods = useForm<any>({
    defaultValues: {
      a: '300',
      b: '200',
      D: '0.240',
      y: '1',
      d: '0.5',
      Tsm: '473.0',
      psm: null,
      tr: '20',
      Tr: null,
      Vmax: null,
      Gsm: '10.00',
      Nmin: null,
      Lmin: null,
      Sp: '1200',
      Spnor: '1000',
      Nminnor: null,
      N: null,
      Gsm1: null,
      Lsm1: null,
    },
  });

  const selectOptions = [
    {
      value: '0.5',
      label: '0,5',
    },
    {
      value: '1',
      label: '1',
    },
  ];

  const handlePerformCalculation = () => {
    const data = methods.getValues();

    const cloneData = cloneDeep(data);

    dispatch(
      systemCalculationsActions.performCalculationOfSmokeRemovalDevices({
        calc_id: systemID,
        data: cloneData,
      })
    );
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!systemState || systemState[name] !== value) {
      handlePerformCalculation();
    }
  };

  useEffect(() => {
    systemState && methods.reset(systemState);
    !systemState && handlePerformCalculation();
  }, [systemState]);

  const checkNotice = () => {
    if (
      systemState?.['D'] &&
      systemState?.['d'] &&
      systemState?.['d'] < 2 * systemState?.['D']
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <div className='flex flex-col gap-8'>
          <div className='py-4 px-2'>
            <Title level={5} className='mb-4'>
              Размеры дымоприемных устройств
            </Title>
            <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
              <InputNumberForm
                fixValue={0}
                addonBefore={<i>a =</i>}
                addonAfter='мм'
                name='a'
                label='Длина или диаметр дымоприемного устройства'
                placeholder={isObserver ? '' : 'Введите значение'}
                onBlur={handleBlur}
                size='large'
                disabled={isObserver}
              />
              <div className='relative'>
                <InputNumberForm
                  addonBefore={<i>b =</i>}
                  addonAfter='мм'
                  fixValue={0}
                  name='b'
                  label='Ширина дымоприемного устройства'
                  placeholder={isObserver ? '' : 'Введите значение'}
                  onBlur={handleBlur}
                  size='large'
                  disabled={isObserver}
                />
                <Typography.Text
                  type='secondary'
                  className='text-xs absolute right-0'
                >
                  <span className='text-blue-500'>
                    0 для дымоприемного устройства круглого сечения
                  </span>
                </Typography.Text>
              </div>
              <InputNumberForm
                fixValue={3}
                addonBefore={<i>D =</i>}
                addonAfter={<i>м</i>}
                disabled
                name='D'
                label='Эквивалентный гидравлический диаметр дымоприемного устройства'
                onBlur={handleBlur}
                size='large'
              />
            </div>
          </div>
          <div className='py-4 px-2'>
            <Title level={5} className='mb-4'>
              Раcположение дымоприемных устройств
            </Title>
            <img src={LocationDeviceImage} className='w-[750px] mb-8' />
            <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
              <div className={`relative mb-7`}>
                <SelectForm
                  label='Коэффициент, учитывающий местоположение дымоприемного устройства (см. изображение выше)'
                  size='large'
                  options={selectOptions}
                  name={`y`}
                  onChange={(newValue: any) => {
                    methods.setValue('y', newValue);
                    handlePerformCalculation();
                  }}
                  disabled={isObserver}
                />
                <Typography.Text
                  type='secondary'
                  className='text-xs absolute right-0'
                >
                  <span className='text-blue-500'>
                    1 - при горизонтальном расположении устройства и размещении
                    его на расстоянии 2D от его оси до стены или перегородки и
                    более, где D – эквивалентный гидравлический диаметр
                    дымоприемного устройства <br />
                    0,5 – в остальных случаях, включая вертикальное размещение
                  </span>
                </Typography.Text>
              </div>
              <div className={`relative mb-7`}>
                <InputNumberForm
                  fixValue={1}
                  addonBefore={<i>d =</i>}
                  addonAfter={<i>м</i>}
                  name='d'
                  label='Расстояние от нижней границы дымового слоя до нижней точки дымоприемного устройства (см. изображение выше)'
                  placeholder={isObserver ? '' : 'Введите значение'}
                  onBlur={handleBlur}
                  size='large'
                  disabled={isObserver}
                />
                {checkNotice() && (
                  <Typography.Text
                    type='secondary'
                    className='text-xs absolute right-0'
                  >
                    <span className='text-rose-500'>
                      d ≥ 2D – условие НЕ выполнено. Необходимо изменить ceчeниe
                      дымоприемного устройства или изменить расстояние от нижней
                      границы дымового слоя до нижней точки дымоприемного
                      устройства
                    </span>
                  </Typography.Text>
                )}
              </div>
              <InputNumberForm
                fixValue={1}
                addonBefore={
                  <i>
                    T<sub>sm</sub> =
                  </i>
                }
                addonAfter={<i>°К</i>}
                name='Tsm'
                label='Средняя температура дымового слоя'
                placeholder={isObserver ? '' : 'Введите значение'}
                onBlur={handleBlur}
                size='large'
                disabled={isObserver}
              />
              <InputNumberForm
                fixValue={3}
                disabled
                addonBefore={
                  <i>
                    ρ<sub>sm</sub> =
                  </i>
                }
                addonAfter={
                  <span>
                    кг/м
                    <sup>3</sup>
                  </span>
                }
                name='psm'
                label='Плотность дымового слоя'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                fixValue={0}
                addonBefore={
                  <i>
                    t<sub>r</sub> =
                  </i>
                }
                addonAfter={<i>°C</i>}
                name='tr'
                label='Средняя температура воздуха в помещении (до начала пожара)'
                placeholder={isObserver ? '' : 'Введите значение'}
                onBlur={handleBlur}
                size='large'
                disabled={isObserver}
              />
              <InputNumberForm
                fixValue={1}
                disabled
                addonBefore={
                  <i>
                    T<sub>r</sub> =
                  </i>
                }
                addonAfter={<i>°К</i>}
                name='Tr'
                label='Средняя температура воздуха в помещении (до начала пожара)'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                fixValue={3}
                disabled
                addonBefore={
                  <i>
                    V<sub>max</sub> =
                  </i>
                }
                addonAfter={
                  <i>
                    m<sup>3</sup>/c
                  </i>
                }
                name='Vmax'
                label='Максимальный расход продуктов горения, удаляемый через одно дымоприемное устройство'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                fixValue={2}
                addonBefore={
                  <i>
                    G<sub>sm</sub> =
                  </i>
                }
                addonAfter='кг/с'
                name='Gsm'
                label='Массовый расход удаляемых продуктов горения'
                placeholder={isObserver ? '' : 'Введите значение'}
                onBlur={handleBlur}
                size='large'
                disabled={isObserver}
              />
              <InputNumberForm
                fixValue={0}
                disabled
                addonBefore={
                  <i>
                    N<sub>min</sub> =
                  </i>
                }
                addonAfter='шт'
                name='Nmin'
                label='Минимальное расчетное количество дымоприемных устройств (с округлением в большую сторону до целого значения)'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                fixValue={2}
                disabled
                addonBefore={
                  <i>
                    L<sub>min</sub> =
                  </i>
                }
                addonAfter='м'
                name='Lmin'
                label='Минимальное расстояние между дымоприемными устройствами (от края до края)'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                fixValue={0}
                addonBefore={
                  <i>
                    S<sub className='not-italic'>п</sub> =
                  </i>
                }
                addonAfter={
                  <i>
                    м<sup>2</sup>
                  </i>
                }
                name='Sp'
                label='Площадь помещения'
                placeholder={isObserver ? '' : 'Введите значение'}
                onBlur={handleBlur}
                size='large'
                disabled={isObserver}
              />
              <InputNumberForm
                fixValue={0}
                addonBefore={
                  <i>
                    S<sub className='not-italic'>п</sub>
                    <sub> нор</sub> =
                  </i>
                }
                addonAfter={
                  <i>
                    м<sup>2</sup>
                  </i>
                }
                name='Spnor'
                label='Нормативная площадь помещения, приходящаяся на одно дымоприемное устройство (не более)'
                placeholder={isObserver ? '' : 'Введите значение'}
                onBlur={handleBlur}
                size='large'
                disabled={isObserver}
              />
              <InputNumberForm
                fixValue={0}
                disabled
                addonBefore={
                  <i>
                    N<sub>min нор</sub> =
                  </i>
                }
                addonAfter='шт'
                name='Nminnor'
                label='Минимальное нормативное количество дымоприемных устройств (с округлением в большую сторону до целого значения)'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                fixValue={0}
                disabled
                addonBefore={<i>N =</i>}
                addonAfter='шт'
                name='N'
                label='Итоговое количество дымоприемных устройств'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                fixValue={2}
                disabled
                addonBefore={
                  <i>
                    G<sub>sm1</sub> =
                  </i>
                }
                addonAfter='кг/с'
                name='Gsm1'
                label='Массовый расход удаляемых продуктов горения через одно дымоприемное устройство'
                onBlur={handleBlur}
                size='large'
              />
              <InputNumberForm
                fixValue={0}
                disabled
                addonBefore={
                  <i>
                    L<sub>sm1</sub> =
                  </i>
                }
                addonAfter={
                  <i>
                    м<sup>3</sup>/ч
                  </i>
                }
                name='Lsm1'
                label='Объемный расход удаляемых продуктов горения через одно дымоприемное устройство'
                onBlur={handleBlur}
                size='large'
              />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

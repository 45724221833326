import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { appSettingsSelectors } from '@/core/redux/slices/app/selectors';
import { appSettingsActions } from '@/core/redux/slices/app/slice';
import { authActions } from '@/core/redux/slices/auth/slice';
import { usersSelectors } from '@/core/redux/slices/users/selectors';
import Logo from '@/images/logo-light.svg';
import User from '@/images/user.svg';

export const Header: React.FC = () => {
  const user = useAppSelector(usersSelectors.user);
  const isSidebarShort = useAppSelector(appSettingsSelectors.isSidebarShort);
  const dispatch = useAppDispatch();

  const { Text } = Typography;

  const onLogOut = () => {
    dispatch(authActions.logout());
  };

  useEffect(() => {
    localStorage.setItem('is_sidebar_short', isSidebarShort.toString());
  }, [isSidebarShort]);

  const handleChangeSidebarSize = () => {
    dispatch(appSettingsActions.setSidebarShort(!isSidebarShort));
  };

  return (
    <div className='bg-primary-dark h-12 flex justify-between items-center px-4'>
      <div className='flex'>
        <Button
          type='link'
          onClick={handleChangeSidebarSize}
          className='text-white pl-[14px] pr-[14px] mr-[25px]'
        >
          <MenuOutlined style={{ fontSize: '21px' }} />
        </Button>
        <img src={Logo} alt='Аэропро.Эксперт' />
      </div>
      {user && (
        <div className='px-3 flex gap-2 items-center'>
          <Link
            to={{
              pathname: '/profile',
            }}
          >
            <img src={User} alt='Аэропро.Эксперт' />
          </Link>

          <Text className='text-white'>{user.name}</Text>
          <Button type='primary' onClick={onLogOut}>
            Выйти
          </Button>
        </div>
      )}
    </div>
  );
};

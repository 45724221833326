import { Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';

const { Text, Title } = Typography;

interface IExitCabin {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  isObserver: boolean;
}

export const ExitCabin: React.FC<IExitCabin> = ({ handleBlur, isObserver }) => {
  return (
    <div>
      <Title level={5}>Двери лифтовой шахты при выходе из кабины лифта</Title>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          addonBefore={<i>n =</i>}
          addonAfter={<i>шт</i>}
          name='n'
          label='Количество кабин в лифтовой шахте'
          placeholder={isObserver ? '' : 'Введите значение'}
          onBlur={handleBlur}
          size='large'
          disabled={isObserver}
        />
        <InputNumberForm
          addonBefore={
            <i>
              h<sub>dl</sub> =
            </i>
          }
          addonAfter={<i>м</i>}
          name='hdl'
          label='Высота двери лифта'
          placeholder={isObserver ? '' : 'Введите значение'}
          onBlur={handleBlur}
          size='large'
          disabled={isObserver}
        />
        <InputNumberForm
          addonBefore={
            <i>
              b<sub>dl</sub> =
            </i>
          }
          addonAfter={<i>м</i>}
          name='bdl'
          label='Ширина двери лифта'
          placeholder={isObserver ? '' : 'Введите значение'}
          onBlur={handleBlur}
          size='large'
          disabled={isObserver}
        />
        <InputNumberForm
          addonBefore={
            <i>
              nF<sub>dl</sub> =
            </i>
          }
          addonAfter={
            <i>
              м<sup>2</sup>
            </i>
          }
          name='nFdl'
          label='Площадь дверей лифтовой шахты при выходе из кабины'
          placeholder={isObserver ? '' : 'Введите значение'}
          fixValue={2}
          onBlur={handleBlur}
          disabled
          size='large'
        />
      </div>
    </div>
  );
};

import { useEffect, useRef, useState } from 'react';
import { LockFilled } from '@ant-design/icons';
import { Alert, Button } from 'antd';

import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';
import { systemActions } from '@/core/redux/slices/smokeExtraction/system/slice';

export interface ISystemAlerts {
  user: any;
  system: any;
  onClosePage: any;
  systemID: number;
  isObserver: boolean;
  calculationLock: any;
}

export const SystemAlerts: React.FC<ISystemAlerts> = ({
  user,
  system,
  onClosePage,
  systemID,
  isObserver,
  calculationLock,
}) => {
  const [checkLocker, setCheckLocker] = useState<boolean | null>(null);

  const intRef = useRef<any>(null);

  const dispatch = useAppDispatch();
  const dataSystem = useAppSelector(systemSelectors.dataSystem);

  useEffect(() => {
    if (!intRef.current && isObserver) {
      const newIntervalId = setInterval(() => {
        dispatch(
          systemActions.getSystem({
            id: systemID,
          })
        );
      }, 10000);
      intRef.current = newIntervalId;
    }

    if (system && !system?.locker) {
      setCheckLocker(false);
    }

    return () => clearInterval(intRef.current);
  }, []);

  useEffect(() => {
    !isObserver && clearInterval(intRef.current);
  }, [isObserver]);

  useEffect(() => {
    if (dataSystem && !dataSystem?.locker) {
      setCheckLocker(false);
    } else {
      setCheckLocker(true);
    }
  }, [dataSystem]);

  const actionButton = () => {
    calculationLock();
    setCheckLocker(true);
  };
  if (checkLocker === null) return null;
  return (
    <div className='px-8'>
      {checkLocker ? (
        <div>
          {system?.locker?.uuid === user?.uuid && (
            <Alert
              message='Вы редактируете расчет'
              type='info'
              showIcon
              closable
              onClose={onClosePage}
            />
          )}

          {system?.locker && system?.locker?.uuid !== user?.uuid && (
            <Alert
              message={`${system?.locker?.name || 'Кто-то'} редактирует расчет. Вы не можете вносить изменения`}
              type='warning'
              icon={<LockFilled />}
              showIcon
            />
          )}
        </div>
      ) : (
        <Alert
          message='Вы можете вносить изменения в файл.'
          type='success'
          showIcon
          action={
            <Button
              size='small'
              style={{ backgroundColor: '#52c41a', color: 'white' }}
              onClick={actionButton}
            >
              Редактировать расчет
            </Button>
          }
        />
      )}
    </div>
  );
};

import { FocusEvent, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Typography } from 'antd';
import _ from 'lodash';

import { InputNumberForm } from '@/components/form/InputNumber';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemCalculationsActions } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

import { DynamicAerodynamicsTable } from './DynamicAerodynamicsTable/DynamicAerodynamicsTable';

interface IAerodynamicsView {
  systemID: number;
  isObserver: boolean;
}

export const AerodynamicsView: React.FC<IAerodynamicsView> = ({
  systemID,
  isObserver,
}) => {
  const systemState = useAppSelector(systemSelectors.systemState);

  const dispatch = useAppDispatch();

  const methods = useForm({
    defaultValues: {
      G: null,
      t: null,
      rho: null,
      Lv: null,
      Pst: null,
      tgrav1: null,
      tgrav2: null,
      hgrav: null,
      Pgrav: null,
      Pst_apo: null,
      Ps: null,
      reserve: null,
      Ps_reserve: null,
      result_table: [
        {
          i: 1,
          G_nach_uch: null,
          t_nach_uch: null,
          T_nach_uch: null,
          t1: null,
          t2: null,
          rho1: null,
          rho2: null,
          cp1: null,
          cp2: null,
          nu1: null,
          nu2: null,
          rho: null,
          Cp_na_uch: null,
          nu: null,
          Ke: null,
          A: null,
          B: null,
          S: null,
          Dek: null,
          v: null,
          Pdin: null,
          Re: null,
          lmbda: null,
          R: null,
          l: null,
          Rl: null,
          Xi_sum: null,
          z: null,
          P_uch_sum: null,
          P_kon_uch: null,
          Gdop: null,
          Gsum: null,
          suction_leakage_type: 'Учитывать',
          delta_P: null,
          Akl: null,
          Bkl: null,
          Fkl: null,
          Sd20: null,
          Sd: null,
          delta_Gkl: null,
          tightness_class: null,
          Fvozd: null,
          Ffas: null,
          Fvozd_sum: null,
          rho_utpod: null,
          A_tc: null,
          B_tc: null,
          C_tc: null,
          D_tc: null,
          delta_Gvozd: null,
          delta_Gpd_sum: null,
          G_kon_uch: null,
          ql: null,
          t_pods: null,
          T_pods: null,
          t1_pods: null,
          t2_pods: null,
          cp1_pods: null,
          cp2_pods: null,
          rho1_pods: null,
          rho2_pods: null,
          rho_pods: null,
          cp_pods: null,
          T_kon_uch: null,
          t_kon_uch: null,
          cp_kon_uch: null,
          rho_kon_uch: null,
          t1_kon_uch: null,
          t2_kon_uch: null,
          cp1_kon_uch: null,
          cp2_kon_uch: null,
          rho1_kon_uch: null,
          rho2_kon_uch: null,
        },
      ],
    },
  });

  const handlePerformCalculation = () => {
    const data = methods.getValues();

    const cloneData = _.cloneDeep(data);

    dispatch(
      systemCalculationsActions.performAerodynamics({
        calc_id: systemID,
        data: cloneData,
      })
    );
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!systemState || systemState[name] !== value) {
      handlePerformCalculation();
    }
  };

  useEffect(() => {
    systemState && methods.reset(systemState);
  }, [systemState]);

  return (
    <FormProvider {...methods}>
      <form className='flex flex-col gap-8'>
        <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            name='G'
            addonBefore={<i>G =</i>}
            addonAfter={<i>кг/с</i>}
            label='Массовый расход перемещаемой среды'
            onBlur={handleBlur}
            size='large'
            disabled
          />
          <InputNumberForm
            name='t'
            addonBefore={<i>t =</i>}
            addonAfter={<i>°C</i>}
            label='Температура перемещаемой среды'
            onBlur={handleBlur}
            size='large'
            disabled
            fixValue={1}
          />
          <InputNumberForm
            name='rho'
            addonBefore={<i>ρ =</i>}
            addonAfter={
              <i>
                кг/м<sup>3</sup>
              </i>
            }
            label='Плотность перемещаемой среды'
            disabled
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            name='Lv'
            addonBefore={
              <i>
                L<sub>v</sub> =
              </i>
            }
            addonAfter={
              <i>
                м<sup>3</sup>/ч
              </i>
            }
            label='Объемный расход перемещаемой среды'
            disabled
            onBlur={handleBlur}
            size='large'
          />
        </div>

        <div className='grid grid-cols-3 gap-4 gap-y-8 items-end pb-8'>
          <InputNumberForm
            fixValue={1}
            name='tgrav1'
            addonBefore={
              <i>
                t<sub>грав.1 </sub>=
              </i>
            }
            addonAfter={<i>°C</i>}
            label='Температура 1 для расчета гравитационного давления'
            onBlur={handleBlur}
            note='Введите температуру перемещаемой среды'
            size='large'
            disabled={isObserver}
          />
          <InputNumberForm
            name='tgrav2'
            addonBefore={
              <i>
                t<sub>грав.2 </sub>=
              </i>
            }
            addonAfter={<i>°C</i>}
            label='Температура 2 для расчета гравитационного давления'
            note='Введите температуру внешней среды'
            onBlur={handleBlur}
            size='large'
            disabled={isObserver}
          />
          <div className='relative'>
            <InputNumberForm
              name='hgrav'
              addonBefore={
                <i>
                  h<sub>грав </sub>=
                </i>
              }
              addonAfter={<i>м</i>}
              label='Высота воздушного столба'
              onBlur={handleBlur}
              size='large'
              disabled={isObserver}
            />
            <Typography.Text
              type='secondary'
              className='text-xs absolute -bottom-10'
            >
              Ввести знак "+" или "-" в зависимости от направления. <br />
              Знак "-" если давление "помогает"
            </Typography.Text>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-4 gap-y-8 items-end'>
          <InputNumberForm
            name='Pst'
            addonBefore={
              <i>
                P
                <sub>
                  st<sub>tN</sub>
                </sub>
                 =
              </i>
            }
            addonAfter={<i>Па</i>}
            disabled
            label='Потери давления при температуре перемещаемой среды'
            fixValue={0}
            onBlur={handleBlur}
            size='large'
          />
          <InputNumberForm
            name='Pgrav'
            addonBefore={
              <i>
                ΔP<sub>грав</sub> =
              </i>
            }
            addonAfter={<i>Па</i>}
            label='Гравитационное давление'
            fixValue={0}
            onBlur={handleBlur}
            disabled
            size='large'
          />
          <InputNumberForm
            name='Pst_apo'
            addonBefore={
              <i>
                P
                <sub>
                  st<sub>tN+g</sub>
                </sub>
                 =
              </i>
            }
            addonAfter={<i>Па</i>}
            label='Потери давления при температуре перемещаемой среды и с учетом гравитационного давления'
            onBlur={handleBlur}
            fixValue={0}
            disabled
            size='large'
          />
          <InputNumberForm
            name='Ps'
            addonBefore={
              <i>
                P<sub>st</sub> =
              </i>
            }
            addonAfter={<i>Па</i>}
            label='Приведенное статическое давление вентилятора'
            onBlur={handleBlur}
            disabled
            size='large'
          />
          <InputNumberForm
            name='reserve'
            addonBefore={<i>k =</i>}
            addonAfter={<i>%</i>}
            label='Запас'
            onBlur={handleBlur}
            size='large'
            disabled={isObserver}
          />
          <InputNumberForm
            name='Ps_reserve'
            addonBefore={
              <i>
                P<sub>st_k</sub> =
              </i>
            }
            addonAfter={<i>Па</i>}
            label='Приведенное статическое давление вентилятора с запасом'
            disabled
            onBlur={handleBlur}
            size='large'
          />
        </div>
        <div>
          <DynamicAerodynamicsTable
            handleChange={handlePerformCalculation}
            handleBlur={handleBlur}
            isObserver={isObserver}
          />
        </div>
      </form>
    </FormProvider>
  );
};

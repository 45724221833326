import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CopyOutlined, DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Table, TableColumnsType, Typography } from 'antd';
import { twMerge } from 'tailwind-merge';

import { InputForm } from '@/components/form/Input';
import { Breadcrumbs } from '@/components/ui/Breadcrumbs/Breadcrumbs';
import { TourItem } from '@/components/ui/TourItem/TourItem';
import { ContentWrapper } from '@/components/ui/wrappers/ContentWrapper/ContentWrapper';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { calculationModalsSelectors } from '@/core/redux/slices/modals/smokeExtraction/calculation/selectors';
import { calculationModalsActions } from '@/core/redux/slices/modals/smokeExtraction/calculation/slice';
import { buildingObjectActions } from '@/core/redux/slices/smokeExtraction/buildingObject/slice';
import { calculationSelectors } from '@/core/redux/slices/smokeExtraction/calculation/selectors';
import {
  calculationActions,
  ICalculationItem,
} from '@/core/redux/slices/smokeExtraction/calculation/slice';
import { formatToDate } from '@/core/utils/dateUtils';

import { useCurrentBuildingObject } from '../hooks/useCurrentBuildObject';

const defaultPageSize = 10;

const { Text, Title } = Typography;

interface ICurrentBuildingObjectEdit {
  buildingObjectID: number;
}

interface IEditObjectForm {
  name: string;
  address: string | null;
  comment: string | null;
}

const CalculationHeader: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const addCalculationModal = useAppSelector(
    calculationModalsSelectors.addCalculationModal
  );

  const [buildingObjectId] = useState(Number(params.id));

  const currentBuildingObject = useCurrentBuildingObject({
    buildingObjectID: buildingObjectId,
  });

  const openAddCalculation = (id: number) => {
    if (!currentBuildingObject) {
      return;
    }

    dispatch(
      calculationModalsActions.openAddCalculationtModal({
        building_object_id: id,
        object_address: currentBuildingObject.address ?? undefined,
        object_name: currentBuildingObject.name,
      })
    );
  };

  return (
    <div className='flex justify-between py-4 px-2'>
      <div className='flex gap-4 items-center'>
        <Button
          icon={<LeftOutlined />}
          onClick={() => navigate('/objects')}
        ></Button>
        <Title level={5} className='!mb-0'>
          Проекты расчетов
        </Title>
      </div>
      <TourItem
        className='w-[292px]'
        content='Давайте создадим ваш первый проект. Проект представляет собой более детализированную часть объекта, например, главное здание или отдельный корпус. Нажмите "Создать проект" - это ваш следующий важный шаг.'
        step={4}
        placement='bottomRight'
        open={!addCalculationModal.isRender}
      >
        <Button
          type='primary'
          onClick={() => openAddCalculation(buildingObjectId)}
        >
          Создать проект
        </Button>
      </TourItem>
    </div>
  );
};

const CalculationObjectEdit: React.FC<ICurrentBuildingObjectEdit> = ({
  buildingObjectID,
}) => {
  const methods = useForm<IEditObjectForm>();
  const dispatch = useAppDispatch();

  const currentBuildingObject = useCurrentBuildingObject({
    buildingObjectID: buildingObjectID,
  });

  useEffect(() => {
    methods.reset({
      name: currentBuildingObject?.name,
      address: currentBuildingObject?.address,
      comment: currentBuildingObject?.comment,
    });
  }, [currentBuildingObject]);

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if (!value) {
        return;
      }

      dispatch(
        buildingObjectActions.updateBuildingObject({
          buildingObjectID: buildingObjectID,
          name: value.name ?? '',
          address: value.address ?? '',
          comment: value.comment,
        })
      );
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  return (
    <FormProvider {...methods}>
      <form>
        <div className='grid grid-cols-2 gap-4'>
          <InputForm name='name' label='Название объекта' size='large' />
          <InputForm name='address' label='Адрес объекта' size='large' />
          <div className='col-span-2'>
            <InputForm
              name='comment'
              label='Комментарий'
              placeholder='Введите комментарий'
              size='large'
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export const CalculationPage: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [buildingObjectId] = useState(Number(params.id));

  const [showSize, setShowSize] = useState<number>(defaultPageSize);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isModal, setIsModal] = useState<any>({});

  const data = useAppSelector(calculationSelectors.calculationListItems);
  const total = useAppSelector(calculationSelectors.calculationListTotal);
  const addCalculationModal = useAppSelector(
    calculationModalsSelectors.addCalculationModal
  );

  const currentBuildingObject = useCurrentBuildingObject({
    buildingObjectID: buildingObjectId,
  });
  const calculationListLock = useAppSelector(
    calculationSelectors.calculationListLock
  );
  const updateCalculationLock = useAppSelector(
    calculationSelectors.updateCalculationLock
  );

  const dispatch = useAppDispatch();

  const handleCancel = () => {
    setIsModal({});
  };

  const handleRemove = (id: number) => {
    dispatch(calculationActions.deleteCalculation({ calculationID: id }));
    setIsModal({});
  };

  const handleCopy = (id: number) => {
    dispatch(calculationActions.copyCalculation({ calculationID: id }));
    setIsModal({});
  };

  const openAddCalculation = (id: number) => {
    if (!currentBuildingObject) {
      return;
    }

    dispatch(
      calculationModalsActions.openAddCalculationtModal({
        building_object_id: id,
        object_address: currentBuildingObject.address ?? undefined,
        object_name: currentBuildingObject.name,
      })
    );
  };

  useEffect(() => {
    if (!buildingObjectId) {
      return;
    }

    dispatch(
      calculationActions.fetchCalculationList({
        building_object_id: buildingObjectId,
        size: showSize,
        page: currentPage,
      })
    );
  }, [buildingObjectId, showSize, currentPage]);

  useEffect(() => {
    if (updateCalculationLock === LoadingStatus.LOADED) {
      dispatch(
        calculationActions.fetchCalculationList({
          building_object_id: buildingObjectId,
          size: showSize,
          page: currentPage,
        })
      );
    }
  }, [updateCalculationLock]);

  useEffect(() => {
    return () => {
      dispatch(calculationActions.setCalculation(null));
      dispatch(calculationActions.setCalculationList(null));
      dispatch(
        calculationActions.setUpdateCalculationLock(LoadingStatus.NEVER)
      );
      dispatch(buildingObjectActions.setCurrentBuildingObject(null));
    };
  }, []);

  const isDataReady =
    calculationListLock === LoadingStatus.LOADED &&
    data &&
    Boolean(data.length);
  const isEmptyData =
    calculationListLock === LoadingStatus.LOADED && !Boolean(data?.length);

  const emptyPlaceholder = (
    <div className='flex text-center h-[684px] items-center justify-center'>
      <div className='max-w-[460px] flex flex-col gap-2.5 items-center'>
        <Title level={5}>Объекты сторительства</Title>
        <Text>
          Здесь пока пусто. Тут будут храниться отдельные проекты по объекту,
          которые мы создали ранее. Это могут быть отдельные корпуса, строения,
          помещения.
        </Text>
        <TourItem
          className='w-[292px]'
          content='Давайте создадим ваш первый проект. Проект представляет собой более детализированную часть объекта, например, главное здание или отдельный корпус. Нажмите "Создать проект" - это ваш следующий важный шаг.'
          step={4}
          placement='bottom'
          open={!addCalculationModal.isRender}
        >
          <Button
            type='primary'
            onClick={() => openAddCalculation(buildingObjectId)}
            icon={<PlusOutlined />}
          >
            Создать проект
          </Button>
        </TourItem>
      </div>
    </div>
  );

  const columns: TableColumnsType<ICalculationItem> = [
    {
      title: 'Номер проекта',
      dataIndex: 'id',
    },
    {
      title: 'Наименование проекта',
      dataIndex: 'calc_name',
      render: (text, record, index) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/objects/${buildingObjectId}/${record.id}`,
              }}
            >
              <Typography.Text className='text-primary'>
                {record.calc_name}
              </Typography.Text>
            </Link>
          </div>
        );
      },
    },
    {
      title: 'Название объекта и адрес',
      dataIndex: 'object_name',
    },
    {
      title: 'Количество расчетов',
      dataIndex: 'systems_count',
    },
    {
      title: 'Дата создания',
      dataIndex: 'created_at',
      render: (text) => formatToDate(text),
    },
    {
      title: '',
      dataIndex: '',
      render: (text, record) => (
        <div className='flex gap-2'>
          <Button
            icon={<CopyOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              setIsModal({
                show: true,
                title: `Вы уверены, что хотите скопировать проект?`,
                handleOk: () => handleCopy(record.id),
                okText: 'Да, скопировать',
                body: (
                  <div>
                    <div>
                      <span className='font-semibold'>
                        Наименование проекта:{' '}
                      </span>
                      {record?.calc_name}
                    </div>
                    <div>
                      <span className='font-semibold'>Название объекта: </span>
                      {record?.object_name}
                    </div>
                    <div>
                      <span className='font-semibold'>Адрес объекта: </span>
                      {record?.object_address}
                    </div>
                    <div>
                      <span className='font-semibold'>
                        Количество расчетов:{' '}
                      </span>
                      {record?.systems_count}
                    </div>
                  </div>
                ),
              });
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              setIsModal({
                show: true,
                title: `Вы уверены, что хотите удалить проект?`,
                handleOk: () => handleRemove(record.id),
                okText: 'Да, удалить',
                okType: 'danger',
                body: (
                  <div>
                    <div>
                      <span className='font-semibold'>
                        Наименование проекта:{' '}
                      </span>
                      {record?.calc_name}
                    </div>
                    <div>
                      <span className='font-semibold'>Название объекта: </span>
                      {record?.object_name}
                    </div>
                    <div>
                      <span className='font-semibold'>Адрес объекта: </span>
                      {record?.object_address}
                    </div>
                    <div>
                      <span className='font-semibold'>
                        Количество расчетов:{' '}
                      </span>
                      {record?.systems_count}
                    </div>
                  </div>
                ),
              });
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumbs
        items={[
          {
            key: 'home',
            title: 'Все расчёты',
          },
          {
            key: 'objects',
            title: <Link to={'/objects'}>Объекты строительства</Link>,
          },
          {
            key: 'currentObject',
            title: currentBuildingObject && currentBuildingObject.name,
          },
        ]}
      />
      <ContentWrapper>
        <div className={twMerge(isDataReady && 'px-6')}>
          {isEmptyData && emptyPlaceholder}
          {!isEmptyData && isDataReady && (
            <div className='flex flex-col gap-4'>
              <CalculationHeader />
              <CalculationObjectEdit buildingObjectID={buildingObjectId} />
              <Table
                columns={columns}
                dataSource={data}
                rowKey='id'
                onChange={(pagination) => {
                  pagination.current && setCurrentPage(pagination.current);
                }}
                onRow={(record) => ({
                  onClick: () => {
                    navigate(`/objects/${buildingObjectId}/${record.id}`);
                  },
                })}
                pagination={{
                  total: total ?? 0,
                  showTotal: (total) => `${total} проектов`,
                  defaultPageSize: defaultPageSize,
                  defaultCurrent: 1,
                  onShowSizeChange(_, size) {
                    setShowSize(size);
                  },
                }}
                scroll={{ y: 'calc(100vh - 415px)' }}
              />
            </div>
          )}
        </div>
      </ContentWrapper>
      <Modal
        centered
        title={isModal?.title}
        open={isModal?.show}
        onOk={isModal?.handleOk}
        okText={isModal?.okText}
        okType={isModal?.okType}
        onCancel={handleCancel}
      >
        {isModal?.body}
      </Modal>
    </div>
  );
};

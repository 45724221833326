import { Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';
import { useAppSelector } from '@/core/redux/hooks';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';
import DirectImage from '@/images/QA_42/Direct.jpg';

export interface IDirectConvectiveColumn {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  isObserver: boolean;
}

const { Text, Title } = Typography;

export const DirectConvectiveColumn: React.FC<IDirectConvectiveColumn> = ({
  handleBlur,
  isObserver,
}) => {
  const systemState = useAppSelector(systemSelectors.systemState);

  return (
    <>
      <div className='grid grid-cols-3 gap-4 gap-y-8 items-end'>
        <img src={DirectImage} />
      </div>

      <div className='grid grid-cols-3 gap-4 gap-y-8 items-end mb-4'>
        <InputNumberForm
          name='r'
          addonBefore={<i>r =</i>}
          label='Коэффициент, характеризующий теплопотери на излучение'
          onBlur={handleBlur}
          note='0,7 - согласно п. 5.5.1.4 NFPA 92-24'
          size='large'
          disabled={isObserver}
        />
        <InputNumberForm
          name='z'
          addonBefore={<i>z =</i>}
          label='Высота незадымляемой зоны'
          addonAfter={<i>м</i>}
          onBlur={handleBlur}
          size='large'
          disabled={isObserver}
        />
        <InputNumberForm
          name='z1'
          addonBefore={
            <i>
              z<sub>1</sub> =
            </i>
          }
          label='Высота факела пламени'
          onBlur={handleBlur}
          addonAfter={<i>м</i>}
          fixValue={2}
          disabled
          size='large'
        />
      </div>
      <div>
        <Text className='block' type='secondary'>
          Статус
        </Text>
        <Text className='block'>{systemState?.status}</Text>
      </div>
      <div className='grid grid-cols-3 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          name='Gk'
          addonBefore={
            <i>
              G<sub>k</sub> =
            </i>
          }
          addonAfter={<i>кг/с</i>}
          label='Массовый расход дымовых газов в конвективной колонке'
          disabled
          onBlur={handleBlur}
          fixValue={2}
          size='large'
        />
        <InputNumberForm
          name='cpk'
          addonBefore={
            <i>
              c<sub>pk</sub> =
            </i>
          }
          addonAfter={<i>кДж/(кг*°К)</i>}
          label='Удельная теплоемкость дымовых газов в конвективной колонке при температуре Tk'
          disabled
          onBlur={handleBlur}
          size='large'
        />
        <InputNumberForm
          name='Tk'
          addonBefore={
            <i>
              T<sub>k</sub> =
            </i>
          }
          addonAfter={<i>°К</i>}
          disabled
          label='Температура дымовых газов в конвективной колонке'
          onBlur={handleBlur}
          size='large'
          fixValue={1}
        />
      </div>
    </>
  );
};

import { Button, Typography } from 'antd';

import { InputNumberForm } from '@/components/form/InputNumber';

const { Text, Title } = Typography;

interface IFan {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  isObserver: boolean;
}

export const Fan: React.FC<IFan> = ({ handleBlur, isObserver }) => {
  return (
    <div>
      <Title level={5}>Параметры работы вентилятора</Title>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          addonBefore={
            <i>
              h<sub>lN</sub> =
            </i>
          }
          name='hlN'
          addonAfter={<i>м</i>}
          label='Высота лифтовой шахты между уровнями нижнего и верхнего этажей'
          placeholder={isObserver ? '' : 'Введите значение'}
          onBlur={handleBlur}
          size='large'
          disabled={isObserver}
        />
        <InputNumberForm
          addonBefore={
            <i>
              h<sub>0l</sub> =
            </i>
          }
          name='h0l'
          addonAfter={<i>м</i>}
          label='Разность между уровнями расположения приемного устройства наружного воздуха и оголовка лифтовой шахты'
          placeholder={isObserver ? '' : 'Введите значение'}
          onBlur={handleBlur}
          size='large'
          disabled={isObserver}
        />
        <InputNumberForm
          addonBefore={
            <i>
              L<sub>v</sub> =
            </i>
          }
          name='Lv'
          addonAfter={
            <i>
              м<sup>3</sup>/ч
            </i>
          }
          label='Объемный расход воздуха, подаваемый в лифтовую шахту'
          placeholder={isObserver ? '' : 'Введите значение'}
          onBlur={handleBlur}
          disabled
          size='large'
        />
        <InputNumberForm
          addonBefore={
            <i>
              P<sub>sv</sub> =
            </i>
          }
          name='Psv'
          addonAfter={<i>Па</i>}
          label='Приведенное статическое давление вентилятора (при раздаче сверху)'
          placeholder={isObserver ? '' : 'Введите значение'}
          onBlur={handleBlur}
          disabled
          size='large'
        />
      </div>
    </div>
  );
};

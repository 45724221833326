import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.appSettings;

const isSidebarShort = createSelector(
  [selector],
  (state) => state.isSidebarShort
);

export const appSettingsSelectors = { isSidebarShort };

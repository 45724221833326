import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  BookOutlined,
  CompassOutlined,
  HomeOutlined,
  InboxOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Menu, MenuProps, Typography } from 'antd';

import { useAppSelector } from '@/core/redux/hooks';
import { appSettingsSelectors } from '@/core/redux/slices/app/selectors';

import { TourItem } from '../TourItem/TourItem';

import styles from './styles.module.css';

type MenuItem = Required<MenuProps>['items'][number];

export const Sidebar: React.FC = () => {
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);

  const isSidebarShort = useAppSelector(appSettingsSelectors.isSidebarShort);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location]);

  const handleClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  const items: MenuItem[] = [
    {
      key: '/',
      label: (
        <Link
          to={{
            pathname: `/`,
          }}
        >
          <Typography.Text>Главная</Typography.Text>
        </Link>
      ),
      title: 'Главная',
      icon: <HomeOutlined />,
    },
    {
      key: 'calculations',
      label: 'Все расчёты',
      icon: <InboxOutlined />,
      children: [
        {
          key: '/objects',
          className: !isSidebarShort ? styles.breakable : '',
          style: { transition: '0.5s' },
          label: (
            <TourItem
              className='w-[300px]'
              align={{ offset: [50, 0] }}
              content={
                'Раздел "Расчеты аэродинамики и систем ПДВ" - ваше основное рабочее пространство. Начните с выбора этого раздела и следуйте этапам инструкции.'
              }
              placement='rightTop'
              step={1}
              open={!location.pathname.includes('/objects')}
            >
              <Link
                to={{
                  pathname: `objects`,
                }}
              >
                <Typography.Text>
                  Расчеты аэродинамики {!isSidebarShort && <br />} и систем ПДВ
                </Typography.Text>
              </Link>
            </TourItem>
          ),
        },
      ],
    },
    {
      key: '/tools',
      label: (
        <Link
          to={{
            pathname: `/tools`,
          }}
        >
          <Typography.Text>Подбор оборудования</Typography.Text>
        </Link>
      ),
      title: 'Подбор оборудования',
      icon: <CompassOutlined />,
    },
    {
      key: 'info',
      label: 'База знаний',
      icon: <BookOutlined />,
      children: [
        {
          key: '/air-exchange',
          label: (
            <Link
              to={{
                pathname: `/air-exchange`,
              }}
            >
              <Typography.Text>Воздухообмен</Typography.Text>
            </Link>
          ),
        },
        {
          key: '/documentation',
          label: (
            <Link
              to={{
                pathname: `/documentation`,
              }}
            >
              <Typography.Text>Документация</Typography.Text>
            </Link>
          ),
        },
        {
          key: '/management',
          label: (
            <Link to='https://aeropro.expert/rukovodstvo-po' target='_blank'>
              <Typography.Text>Руководство пользователя</Typography.Text>
            </Link>
          ),
        },
        {
          key: '/blog',
          label: (
            <Link
              to='https://aeropro.expert/blog#!/tfeeds/339099028101/c/%D0%92%D0%B8%D0%B4%D0%B5%D0%BE'
              target='_blank'
            >
              <Typography.Text>Блог Аэропро.Эксперт</Typography.Text>
            </Link>
          ),
        },
      ],
    },
    {
      key: '/profile',
      label: (
        <Link
          to={{
            pathname: `profile`,
          }}
        >
          <Typography.Text>Личный кабинет</Typography.Text>
        </Link>
      ),
      title: 'Личный кабинет',
      icon: <UserOutlined />,
    },
  ];

  return (
    <div className='z-10 h-full bg-white shadow-[0px_2px_8px_0px_rgba(0,0,0,0.15)] flex flex-col justify-between overflow-hidden'>
      <Menu
        onClick={handleClick}
        style={{ width: isSidebarShort ? '' : '256px', maxWidth: '256px' }}
        selectedKeys={[current]}
        mode='inline'
        items={items}
        defaultOpenKeys={['calculations', 'personal', 'info']}
        inlineCollapsed={isSidebarShort}
      />

      {!isSidebarShort && (
        <div className='p-4 gap-2 flex flex-col'>
          <Button
            type='primary'
            onClick={() => window.open('https://t.me/AeroPro_Expert_Bot')}
          >
            Чат с экспертом
          </Button>
          <Button
            type='link'
            onClick={() =>
              window.open(
                'https://forms.yandex.ru/cloud/66895defc417f32b335c12d2/'
              )
            }
          >
            Предложить идею
          </Button>
          <Button
            type='link'
            onClick={() => window.open('https://t.me/+8c9XF-YlFAZkYjky')}
          >
            Обратная связь
          </Button>
        </div>
      )}
    </div>
  );
};

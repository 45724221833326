import { InputNumberForm } from '@/components/form/InputNumber';
import OpeningImage from '@/images/QA_42/Opening.jpg';

interface IOpeningFlow {
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  isObserver: boolean;
}

export const OpeningFlow: React.FC<IOpeningFlow> = ({
  handleBlur,
  isObserver,
}) => {
  return (
    <>
      <img src={OpeningImage} className='w-[500px]' />
      <div className='grid grid-cols-3 gap-4 gap-y-8 items-end mb-4'>
        <InputNumberForm
          name='r'
          addonBefore={<i>r =</i>}
          label='Коэффициент, характеризующий теплопотери на излучение'
          note='0,7 - согласно п. 5.5.1.4 NFPA 92-24'
          onBlur={handleBlur}
          size='large'
          disabled={isObserver}
        />
      </div>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          name='Aw'
          addonBefore={
            <i>
              A<sub>w </sub>=
            </i>
          }
          addonAfter={
            <i>
              м<sup>2 </sup>
            </i>
          }
          label='Площадь проема горящего помещения'
          onBlur={handleBlur}
          size='large'
          disabled={isObserver}
        />
        <InputNumberForm
          name='Hw'
          addonBefore={
            <i>
              H<sub>w </sub>=
            </i>
          }
          addonAfter={<i>м</i>}
          label='Высота проема горящего помещения'
          onBlur={handleBlur}
          size='large'
          disabled={isObserver}
        />
        <InputNumberForm
          name='Zw'
          addonBefore={
            <i>
              Z<sub>w </sub>=
            </i>
          }
          addonAfter={<i>м</i>}
          label='Расстояние от верха проема горящего помещения до нижней границы дымового слоя'
          onBlur={handleBlur}
          size='large'
          disabled={isObserver}
        />
      </div>
      <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
        <InputNumberForm
          name='a'
          addonBefore={<i>a =</i>}
          label=''
          onBlur={handleBlur}
          addonAfter={<i>м</i>}
          fixValue={2}
          disabled
          size='large'
        />
        <InputNumberForm
          name='cpk'
          addonBefore={
            <i>
              с<sub>pk</sub> =
            </i>
          }
          addonAfter={<i>кДж/кг*°К</i>}
          label='Удельная теплоемкость дымовых газов в конвективной колонке при температуре Tk'
          onBlur={handleBlur}
          disabled
          size='large'
        />
        <InputNumberForm
          name='Tk'
          addonBefore={
            <i>
              T<sub>k</sub> =
            </i>
          }
          addonAfter={<i>°К</i>}
          label='Температура дымовых газов в конвективной колонке'
          fixValue={1}
          onBlur={handleBlur}
          disabled
          size='large'
        />
      </div>
    </>
  );
};
